$breakpoints: (
  'sm': 'screen and (max-width: 400px)',
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 1000px)',
  'xl': 'screen and (max-width: 1200px)',
) !default;
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}


html, body, h1, h2, h3, h4, ul, ol, dl, li, dt, dd, p, div, span, img, a, table, tr, th, td,figure {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-size: 100%;
  vertical-align:baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
article, header, footer, aside, figure, figcaption, nav, section {
  display:block;
}
body {
  line-height: 1;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
ol, ul {
  list-style: none;
  list-style-type: none;
}

body, html {
  margin: 0 auto;
  padding: 0;
  /*max-width: 960px;*/
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans JP' , "ヒラギノUD丸ゴ Std W6", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "YuGothic", "Yu Gothic", "游ゴシック", "メイリオ", "Meiryo", "AxisStd-Regular", sans-serif;
  background-color: #f5f5f5;
  color: #000;
  height: 100%;
  line-height: 1.6;
  font-feature-settings : "palt";

}

img {
    max-width: 100%;
    height: auto;
  @include mq(md) {
    max-width: 100%;
    height: auto;
  }
}

.img_full {
    max-width: width auto;
    width: 100%;
    height: auto;
  @include mq(md) {
    max-width: 100%;
    height: auto;
  }
}
