@import "lib/compass";
@import "reset";
@import "swiper";



.swiper-container {
      width: 100%;
}


//-------------------------------------------------//
// vars
//-------------------------------------------------//
$title-size_01:34px;
$title-size_01_sp:18px;
$title-size_02:44px;
$title-size_02_sp:24px;
$title-size_03:40px;
$title-size_03_sp:20px;
$text-size_01:26px;
$text-size_01_sp:16px;
$text-size_02:20px;
$text-size_02_sp:12px;
$text-size_03:30px;
$text-size_03_sp:20px;
$text-size_04:40px;
$text-size_04_sp:20px;
$base-color_01:#ff6a14;
$base-color_02:#FFFFFF;
$base-color_03:#ffff66;
$base-color_04:#42a160;




/**********************************************************************/
/**********************************************************************/
/* COMMON *************************************************************/
/**********************************************************************/
/**********************************************************************/

h2 {
  font-size: 30px;
  @include mq(md) {
    font-size: 22px;
  }
}
h3 {
  font-size: 26px;
  font-weight: bold;
  @include mq(md) {
    font-size: 20px;
  }
}
h4 {
  font-size: 24px;
  font-weight: bold;
  @include mq(md) {
    font-size: 15px;
  }
}

.dispaly_PC {
  display: block;
  @include mq(md) {
    display: none;
  }
}

.dispaly_SP {
  display: none;
  @include mq(md) {
    display: block;
  }
}

.dispaly_SP img {
  // width: 100%;
  max-width: 100%;
}

.txt-small {
  @include mq(md) {
    font-size: 18px;
    font-weight: bold;
  }
}

.disp_block_SP {
  @include mq(md) {
    display: block;
  }
}




//-------------------------------------------------//
// layout
//-------------------------------------------------//
.l-container {
  overflow: hidden;
  @include mq(md) {
      overflow: hidden;
    }
}

.l-header {
  position: fixed;
  width: 100%;
  background-color: rgba(255,255,255,0.2);
  z-index: 9999999999;
    .nav {
      text-align: right;
      @include mq(md) {
        text-align: center;
      }
      ul {
        padding: 10px;
        li {
          display: inline-block;
          margin-left: 10px;
          @include mq(md) {
            // font-size: 12px;
            font-size: 10px;
          }
          &::after {
            content: "|";
            font-size: 10px;
            margin-left: 10px;
            position: relative;
            top: -3px;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          a {
            &:link {
              color: #333;
              text-decoration: none;
            }
            &:hover {
              opacity: .6;
            }
            &:visited {
              color: #333;
              text-decoration: none;
            }
          }
        }
      }
    }
}


.l-main {
  background-color: #FFF;
  color: #FFFFFF;
  .l-inner {
    padding: 10px 0;
    text-align: center;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @include mq(md) {
      margin: 0;
      padding: 0;
      height: 100vh;
      min-height: 300px;
    }
    .logo {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      @include mq(md) {
        width: 80%;
      }
      img {
        width: 400px;
      }
    }
    .navbtn {
      position: absolute;
      top: 20px;
      right: 20px;;

    }
  }
}

.flexbox-container-vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.l-section {
  padding: 160px 40px;
   @include mq(md) {
      padding: 60px 0;
  }
  &.concept {
    padding-left: 0;
    padding-right: 0;
    // background-image: linear-gradient(-90deg, #FFD105, #10FAFE);
    background-color: #faf2e4;
    @include mq(md) {
      padding: 200px 0;
    }
  }
  &.about {
    // background-image: linear-gradient(-90deg, #FFE0EF, #FFD4AE);
    background-color: #fae5e4;
    background-size: cover;
    .l-inner {
      background-color: rgba(255,255,255,0.8);
      padding: 40px;
      @include mq(md) {
        padding: 40px 20px;
      }
      .c-ttl-01 {
        margin-bottom: 10px;
      }
    }
  }
  &.works {
    padding-left: 0;
    padding-right: 0;
    background-color: #ddf1fd;
  }
  &.details {
  //  background-image: linear-gradient(-60deg, #BF47FF, #FF8247);
   background-color: #f2f2f2;
    // color: #FFFFFF;
  }
   &.recruit {
   background-color: #fafafa;
  }
  &.map {
    padding: 0;
    .l-inner {
      width: 100%;
      iframe {
        width: 100%;
        height: 600px;
        @include mq(md) {
          width: 90%;
          height: 400px;
        }
      }
    }
  }
}

.l-inner {
  width: 1020px;
  margin: 0 auto;
    @include mq(md) {
      width: auto;
      margin: 0 20px;
      padding: 0 20px;
  }
}

.l-cont {
  &_01 {
    background-color: #f5f5f5;
  }
  &_02 {
    background-color: #f5eee0;
  }
}

.l-footer {
  background-color: #FFFFFF;
  padding: 60px;
  color: #000000;
  font-size: 12px;
  text-align: center;
  @include mq(md) {
    }
}

.l-aboutlist {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  @include mq(md) {
            font-size: 12px;
    }
  .l-aboutlist-item {
    padding: 0 100px;
    position: relative;
    text-align: center;
    @include mq(md) {
      padding: 0;
    }
    &:after {
      content: "";
      background: url(../images/img_arrow_01.png) no-repeat 0 0;
      display: block;
      width: 80px;
      height: 64px;
      position: absolute;
      right: -30px;
      top: 30%;
      @include mq(md) {
        width: 40px;
        height: 32px;
      }
    }
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    .image {
      height:130px;
      line-height: 130px;
      img {
        vertical-align: middle;
        @include mq(md) {
          width: 60%;
        }
      }
    }
    .text-en {
      font-size: 18px;
      margin-bottom: 10px;
      @include mq(md) {
        font-size: 12px;
      }
    }
    .text-jp {
      font-size: 18px;
      text-align: center;
      @include mq(md) {
        font-size: 12px;
      }
    }
  }
}


//-------------------------------------------------//
// COMPONENT
//-------------------------------------------------//
.c-ttl {
  &-01 {
    text-align: center;
    margin-bottom: 60px;
    // font-style: italic;
    &:after{
      content: "";
      display: block;
      border-bottom: 4px solid #CCC;
      width: 30px;
      margin: 0 auto;
    }
    span {
      display: block;
      font-size: 12px;
      padding-bottom: 8px;

    }
  }
  &-02 {
    text-align: center;
    margin-bottom: 60px;
    // font-style: italic;
    &:after{
      content: "";
      display: block;
      border-bottom: 4px solid #ffffff;
      width: 30px;
      margin: 0 auto;
    }
  }
}

.c-definelist {
  &-01 {
    display: flex;
    flex-wrap: wrap;
    .ttl {
      width: 47%;
      text-align: right;
      padding-bottom: 40px;
      @include mq(md) {
        width: 30%;
        font-size: 12px;
      }
    }
    .text {
      width: 50%;
      padding-left: 3%;
      text-align: left;
      @include mq(md) {
        width: 67%;
        font-size: 12px;
      }
    }
  }
}

.c-workitem {
  position:relative;
  a {
    &:hover {
      opacity: .6;
    }
  }
  .text-col {
    // position: absolute;
    bottom: 20px;
    left: 10px;

  }
  .text-01 {
    span {
      display: inline-block;
      background-color: #000000;
      color: #FFFFFF;
      padding: 0 5px;
    }

  }
  .text-02 {
    span {
      font-size: 12px;
      display: inline-block;
      // background-color: #000000;0
      // color: #FFFFFF;
      padding: 0 5px;
    }
  }
}

.c-mainimage {
  width: 80%;
  @include mq(md) {
        width: 100%;
      }
  img {
    vertical-align: bottom;
    width: 70%;
    @include mq(md) {
        width: 100%;
      }
  }
}

.c-text-01 {
  text-align: center;
  @include mq(md) {
    text-align: left;
    font-size: 12px;
  }
}

.swiper-slide {
  margin: 0 10px;
  @include mq(md) {
  }
}

#pageTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  img {
    width: 32px;
    height: auto;
  }
}
